import { HeroVideo } from '@payfit/growth-platform-brand-system-v2/templates/HeroVideo';
import { graphql } from 'gatsby';
import { Hero } from 'libs/growth-platform-brand-system-v2/src/templates/Hero';
import { useScreenSize } from 'libs/growth-platform-brand-system-v2/src/utils/utils';
import { morphism } from 'morphism';
import React from 'react';
import { TemplateBlocksZone } from '../../components/TemplateBlocksZone';
import Layout from '../../layout';
import { HeadWrapper } from '../../layout/HeadWrapper';
import { schemaBaseTemplate } from '../../morphismSchema/pages/schemaBaseTemplate';
import { schemaHero } from '../../morphismSchema/templates/schemaHero';
import { schemaHeroVideo } from '../../morphismSchema/templates/schemaHeroVideo';

export const Head = ({ data, pageContext, location }: any) => (
  <HeadWrapper
    data={data}
    pageContext={pageContext}
    location={location}
    templateName="ClientsByPage"
    dataName={'datoCmsClientsByPage'}
    largestContentfullPaintImg={null}
  />
);

export const query = graphql`
  query DatoClientsByPage($locale: String, $id: String) {
    datoCmsClientsByPage(locale: $locale, id: { eq: $id }) {
      ...ClientsByPage
    }
  }
`;

const ClientsByPage = ({ data, pageContext }: any) => {
  const doc: any = data.datoCmsClientsByPage;
  const screenSize = useScreenSize();

  if (!doc) return <></>;

  const tracking = doc.sizeLink?.clientSize
    ? { companySize: doc.sizeLink?.clientSize }
    : {};

  const _data = morphism(schemaBaseTemplate, {
    ...doc,
    screenSize,
    pageContext,
  });
  const hero = _data?.hero;

  return (
    <Layout
      pageContext={pageContext}
      data={doc}
      tracking={tracking}
      templateName="ClientsByPage"
    >
      {hero && hero.__typename === 'DatoCmsHeroVideo' && (
        <HeroVideo {...(morphism(schemaHeroVideo, hero) as any)} />
      )}
      {hero && hero.__typename === 'DatoCmsHero' && (
        <Hero
          {...(morphism(schemaHero, {
            ...hero,
            buttonLink: '#key-functionalities',
          }) as any)}
        />
      )}
      {_data?.blocks.length > 0 && <TemplateBlocksZone {..._data} />}
    </Layout>
  );
};

export default ClientsByPage;
